import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form } from "react-bootstrap";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import { useNavigate, useLocation } from "react-router-dom";
import BannerService from "../../service/api/banner.service";
import { BorderSpinner } from "../../common/Spinner";
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

const ViewBanner = () => {
  const [isLoading, setIsLoading] = useState(true);

  const bannerService = BannerService();
  const location = useLocation();
  const { id } = location.state;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    beforeSignInBanner: [],
    afterSignInBanner: [],
  });

  const getBanner = async () => {
    const res = await bannerService.getBannerById(id);
    if (res?.code === 200) {
      setIsLoading(false);
      setFormData({
        name: res?.data?.name,
        beforeSignInBanner: res?.data?.beforeSignInBanner || [],
        afterSignInBanner: res?.data?.afterSignInBanner || [],
      });
    }
  };

  useEffect(() => {
    getBanner();
  }, [id]);

  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className="align-items-center mb-4">
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between mt-0">
                <h4 className="card-title w-100">View Banner</h4>
                <Button
                  variant="primary"
                  onClick={() => navigate("/banner")}
                >
                  Back
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center"><BorderSpinner /></div>
            ):(<div className="row">
                {/* Name */}
                <Col md={4} className="mt-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.name}
                    readOnly
                  />
                </Col>

                {/* Before Sign-In Banner */}
                <Col md={12} className="mt-3">
                  <Form.Label>Before Sign-In Banner</Form.Label>
                  <div className="mt-3 d-flex flex-wrap gap-2">
                    {formData.beforeSignInBanner.map((banner, index) => (
                      <div key={index} className="text-center">
                        <img
                          src={`${imageBaseUrl}/${banner.url}`}
                          alt={`Before Sign-In Preview ${index}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                        <div className="mt-2">
                          <span>
                            Link:{" "}
                            {banner.link ? (
                              <span
                                // href={banner.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-decoration-none text-primary "
                              >
                                {banner.link}
                              </span>
                            ) : (
                              "No link provided"
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>

                {/* After Sign-In Banner */}
                <Col md={12} className="mt-3">
                  <Form.Label>After Sign-In Banner</Form.Label>
                  <div className="mt-3 d-flex flex-wrap gap-2">
                    {formData.afterSignInBanner.map((banner, index) => (
                      <div key={index} className="text-center">
                        <img
                          src={`${imageBaseUrl}/${banner.url}`}
                          alt={`After Sign-In Preview ${index}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                        <div className="mt-2">
                          <span>
                            Link:{" "}
                            {banner.link ? (
                              <span
                                
                                // href={banner.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-decoration-none text-primary "
                              >
                                {banner.link}
                              </span>
                            ) : (
                              "No link provided"
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </div>)}
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewBanner;
