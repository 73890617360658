import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import { useSelector } from 'react-redux';

const WelcomePage = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const isAuthenticated = useSelector((state) => state.user.user);
  const user=JSON.parse(localStorage.getItem('user'))
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getGreeting = () => {
    const hour = currentTime.getHours();
    if (hour < 12) return { text: "Good Morning", emoji: "🌅" };
    if (hour < 18) return { text: "Good Afternoon", emoji: "☀️" };
    return { text: "Good Evening", emoji: "🌙" };
  };

  const greeting = getGreeting();

  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div className="main main-app bg-primary text-white p-3 p-lg-4">
        <Container fluid className='d-flex justify-content-center align-items-center pt-5 '>
                  <div className="text-center  bold ">
                      <h2 className="text-white mb-4">Hello, {user?.name || ''}</h2>
                      <h3>{greeting.text} {greeting.emoji} </h3>
            <h4 className="mb-4 mt-4">Welcome to your Dashboard!</h4>
            <p className="lead">
            Your dashboard is ready—explore and make it work for you.
            </p>
            <div className="mt-4 p-4 rounded" style={{ background: 'rgba(255, 255, 255, 0.1)' }}>
              <h5>Current Time</h5>
              <h2 style={{ fontFamily: 'monospace' }}>
                {currentTime.toLocaleTimeString()}
              </h2>
              <p className="mb-0">
                {currentTime.toLocaleDateString(undefined, {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </p>
            </div>
          </div>
        </Container>
      </div>

      <style jsx global>{`
        .main-app {
          background-color: #f8f9fa;
        }
      `}</style>
    </React.Fragment>
  );
};

export default WelcomePage;
