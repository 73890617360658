// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import { useDispatch } from "react-redux";
import createAxiosInstance from "../api.instance";
import { endApiRequest, startApiRequest } from "../../Redux/Action";


const UserService = () => {
  const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
  const dispatch = useDispatch();
const axiosInstance = createAxiosInstance(bearerToken);
  const createStaff = async (requestData) => {
    return await axiosInstance
      .post(`/admin/auth/subadmin`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getUser = async ({page, limit, searchBy,merchantId,fromDate,toDate}) => {
    return await axiosInstance
      .get(`/admin/user?page=${page}&limit=${limit}&searchBy=${searchBy}&fromDate=${fromDate || ''}&toDate=${toDate || ''}&merchantId=${merchantId || ''}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateUser = async (id, requestData) => {
    return await axiosInstance
      .patch(`/admin/user/${id}`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateStaffStatus = async ( requestData) => {
    return await axiosInstance
      .post(`/admin/user`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const deleteStaff = async (id) => {
    return await axiosInstance
      .delete(`/admin/user/${id}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getUserById = async (id) => {
    return await axiosInstance
      .get(`/admin/user/${id}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // users/wallet-details/id/fromDate=23-05-2024&toDate=23-05-2024
  const getAdminUserWalletDetails = async (filter, id) => {
    console.log(filter, "=======from date")
    dispatch(startApiRequest());
    return await axiosInstance
      .get(`users/admin/wallet-details/${id}?page=${filter?.page}&limit=${filter?.limit}&userId=${filter?.user}&status=${filter?.status}&transactionType=${filter?.transactionType}&searchBy=${filter?.searchBy}&fromDate=${filter?.fromDate}&toDate=${filter?.toDate}`,  { headers: {} })
      .then((response) => {
        console.log("walletDetails =============", response);
    dispatch(endApiRequest());

        return response.data; // Return response data
      })
      .catch((error) => {
    dispatch(endApiRequest());

        console.error("Data fetch failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // bank/admin/6637d17aef6b18e960785cc7?page=1&limit=10&searchBy=

  const getAdminUserBankDetails = async (filter, id) => {
    console.log("bank filters",filter)
    return await axiosInstance
      .get(`bank/admin/${id}?searchBy=${filter?.searchBy}`,  { headers: {} })
      .then((response) => {
        console.log("BankDetails =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Bank Data fetch failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // admin/user/list/dropdown

  const getUserList = async () => {
    // console.log("bank filters",filter)
    return await axiosInstance
      .get(`admin/user/list/dropdown`,  { headers: {} })
      .then((response) => {
        console.log("user list =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("user list fetch failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  return {
    getUserList,
    getAdminUserBankDetails,
    getAdminUserWalletDetails,
    createStaff,
    getUser,
    updateUser,
    getUserById,
    updateStaffStatus,
    deleteStaff
  };
};

export default UserService;
