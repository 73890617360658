import React, { useEffect, useState, useRef } from "react";
import { Card, Row, Col, Badge, Dropdown, Table, Pagination, Form } from 'react-bootstrap';
import useSkinSwitcher from "./skin";
import ReactPaginate from "react-paginate";
import AuthService from "../service/api/auth.service";
import { Link, useNavigate } from "react-router-dom";

import capitalizeFirstLetter from '../helper/Capitalise'
import './table.css'
import { useSelector } from "react-redux";
import { BorderSpinner } from "./Spinner";
const TableWithPagination = ({
  addType='',
  tableType,
  data,
  itemsPerPage,
  columns,
  currentPage,
  totalPages1,
  totalData,
  pagination,
  setPagination,
  handleSearchChange,
  moduleId,
  redirectUrl
}) => {
  const isLoading = useSelector((state) => state.state.loading);
  const [currentPage1, setCurrentPage] = useState(currentPage);
  const [currentData, setCurrentData] = useState([...data]);
  const [pageSize, setPageSIze] = useState(itemsPerPage);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState(null);
  const { skin, setSkin } = useSkinSwitcher();
  const [totalPages, setTotalPages] = useState(totalPages1);

  const theme = useSelector((state) => state.ui.skin);
  const authService = AuthService();
  const navigate = useNavigate();

  console.log(theme, "Mytheme")
  useEffect(() => {
    // pageSize.current = itemsPerPage;
    // currentPage1.current = currentPage;
    setPageSIze(itemsPerPage)
    setCurrentPage(currentPage)
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    const startIndex = (currentPage1 - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    console.log("rawData", totalPages1);


    const rawData = data.slice(startIndex, endIndex);
    // setCurrentData(data);
    setTotalPages(Math.ceil(totalData / pageSize));
  }, [data, sortBy, sortOrder, currentPage1, pageSize]);
  console.log("rawData", currentData);

  const handlePageChange = (page) => {
    console.log(page, "currentPage==========", totalPages)
    setCurrentPage(page);
    // currentPage1 = page;
    setPagination({ ...pagination, page })
  };

  const PageSizeSelector = ({ pageSize }) => (
    <Form.Group controlId="pageSize">
      <Form.Control
        as="select"
        value={pageSize}
        onChange={(e) => {
          setPageSIze(parseInt(e.target.value));
          console.log("=====>select", parseInt(e.target.value));
        }}
      >
        <option value="2">2</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
      </Form.Control>
    </Form.Group>
  );

  const handleSort = (column) => {
    const accessor = column.accessor || column;
    if (sortBy === accessor) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(accessor);
      setSortOrder("asc");
    }
  };
  const handlePageClick = (event) => {
    setPagination((preValue) => ({
      ...preValue,
      page: event.selected + 1
    }));
  }
  console.log("isLoading CurrentData", isLoading, currentData);
  const moduleIdList = [3, 6, 10, 7, 11]
  return (
    // <Row>
    <>


      <Card.Body >
        <div className="d-flex py-3 gap-2 align-items-center justify-content-between">
          {!(moduleId == 6 || (tableType && tableType == 'wallet') || moduleId == 7 || moduleId == 9||moduleId == 18) && <div className="form-search me-auto">
            <input
              type='search'
              // value={pagination.searchBy}
              defaultValue={pagination.searchBy}
              className="form-control"
              placeholder={`${moduleId == 11 ? 'Search by match Id' : 'Search'}`}
              onChange={handleSearchChange}
            />
            <i className="ri-search-line"></i>
            {/* ticketList */}
          </div>}
          <div></div>

          {!(moduleIdList.includes(moduleId) || (moduleId == 15 && (tableType && tableType === 'ticketList'))) && authService.checkAccess(moduleId, "add") && <button
            type="button"
            className="btn btn-primary"
            onClick={addType==='modal'?redirectUrl:() => navigate(redirectUrl)}
          >
            Add
          </button>}
        </div>
        <div className="table-responsive-sm" style={{ overflow: 'auto', minHeight: "300px" }} >

          <Table className={`table-bordered  table-centered ${theme === 'dark' ? 'customTable' : ''} mb-0`}>
            <thead>
              <tr>
                <th>#</th>
                {columns.map((column, index) => (
                  <th className="text-nowrap" key={index} onClick={() => handleSort(column)}>
                    {tableType && (tableType === 'transaction' || tableType == 'wallet') ? (column.header || column)
                      : capitalizeFirstLetter(column.header || column)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={columns.length + 1} className="text-center">
                    <BorderSpinner />
                  </td>
                </tr>
              ) : data.length !== 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{(currentPage1 - 1) * pageSize + index + 1}</td>
                    {columns.map((column, columnIndex) => (
                      <td className="text-nowrap" key={columnIndex}>
                        {typeof column === "object" && column.Cell ? (
                          column.Cell({
                            value: item[column.accessor],
                            row: item // Ensure 'row' is passed correctly to Cell function
                          })
                        ) : item[column.accessor] ? (
                          typeof item[column.accessor] === "string" ?
                            capitalizeFirstLetter(item[column.accessor]) :
                            item[column.accessor]
                        ) : (
                          "N/A" // Default value when item[column.accessor] is undefined
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length + 1} className="text-center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>

            {/* <tbody>
              {!isLoading && currentData.length !== 0? (
                currentData.map((item, index) => (
                  <tr key={index}>
                    <td>{(currentPage1 - 1) * pageSize + index + 1}</td>
                    {columns.map((column, columnIndex) => (
                      <td key={columnIndex}>
                        {typeof column === "object" && column.Cell ? (
                          column.Cell({
                            value: item[column.accessor],
                            row: item  // Ensure 'row' is passed correctly to Cell function
                          })
                        ) : (
                          // Handle cases where item[column.accessor] might not exist
                          item[column.accessor] ? (
                            typeof item[column.accessor] === 'string' ?
                              capitalizeFirstLetter(item[column.accessor]) :
                              item[column.accessor]
                          ) : (
                            "N/A" // Default value when item[column.accessor] is undefined
                          )
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <>
                  {!isLoading  && currentData.length == 0? <tr>
                    <td colSpan={columns.length + 1} className="text-center">
                      No Record Found
                    </td>
                    </tr> :
                      <tr>
                      <td colSpan={columns.length + 1} className="text-center">
                          <BorderSpinner/>
                         
                      </td>
                    </tr>

                  }
                </>
              )}
            </tbody> */}
          </Table>

        </div>

      </Card.Body>
      <Card.Footer>
        <div className="d-lg-flex align-items-center text-center mt-2">
          {(<ReactPaginate
            containerClassName={"pagination pagination-rounded d-inline-flex ms-auto align-item-center mb-0"}
            pageClassName={"page-item"}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            forcePage={currentPage1 - 1}
          />)}
        </div>
      </Card.Footer>
    </>

  );
};

export default TableWithPagination;
