import React from "react";
import { Card } from "react-bootstrap";
import formatDateTime from "../../helper/DateFormate";

const userDetail = ({ userData }) => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="sub-service-title">
                        <h3><span>Personal Details</span></h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Username:</span>
                        <span className="service-view"> {userData?.userDetail?.username?.toUpperCase()} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Email:</span>
                        <span className="service-view"> {userData?.userDetail?.email} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Phone Number:</span>
                        <span className="service-view"> {userData?.userDetail?.phone} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Email Verified:</span>
                        <span className="service-view"> {userData?.userDetail?.isVerifyEmailOtp ? 'Yes' : 'No'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Bet Allowed:</span>
                        <span className="service-view"> {userData?.userDetail?.isBettingAllowed ? 'Yes' : 'No'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Withdrawal Allowed:</span>
                        <span className="service-view"> {userData?.userDetail?.isWithdrawalAllowed ? 'Yes' : 'No'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Login Allowed:</span>
                        <span className="service-view"> {userData?.userDetail?.isLoginAllowed ? 'Yes' : 'No'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Phone Verified:</span>
                        <span className="service-view"> {userData?.userDetail?.phoneVerified ? 'Yes' : 'No'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Registered Date:</span>
                        <span className="service-view"> {formatDateTime(userData?.userDetail?.createdAt)} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Registered Location:</span>
                        <span className="service-view">
                            {userData?.userDetail?.resgisterCity && userData?.userDetail?.resgisterState && userData?.userDetail?.resgisterCountry
                                ? `${userData.userDetail.resgisterCity}, ${userData.userDetail.resgisterState} (${userData.userDetail.resgisterCountry})`
                                : "N/A"}
                        </span>

                        {/* <span className="service-view"> {userData?.userDetail?.resgisterCity || "N/A"}, {userData?.userDetail?.resgisterState || ''} {userData?.userDetail?.resgisterCountry ? `( ${userData?.userDetail?.resgisterCountry} )` : ''} </span> */}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">User ID:</span>
                        <span className="service-view"> {userData?.userDetail?.id} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Registered IP:</span>
                        <span className="service-view"> {userData?.userDetail?.resgisterIP || 'N/A'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Registered Device Fingerprint:</span>
                        <span className="service-view"> {userData?.userDetail?.fingerprint || 'N/A'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Referred By:</span>
                        <span className="service-view"> {userData?.userDetail?.isReferred?.status ? userData?.userDetail?.isReferred?.referredByUserId : 'N/A'} </span>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="subservice-row">
                        <span className="service-title">Referred Users:</span>
                        <span className="service-view">
                            {userData?.userDetail?.haveReferred?.status ? (
                                userData.userDetail.haveReferred.referredUsers.map((user) => (
                                    <div key={user.userId}>
                                        {user.username} (ID: {user.userId})
                                    </div>
                                ))
                            ) : (
                                'N/A'
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default userDetail;
