import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';
import ExposureService from '../../service/api/exposure.service';
import { toast } from 'react-toastify';
import GameSettingService from '../../service/api/game-setting.service';
import { BorderSpinner } from '../../common/Spinner';

const ViewExposure = () => {
  const gameService = GameSettingService();

  const navigate = useNavigate();
  const exposureService = ExposureService();

  const [formValues, setFormValues] = useState({
    // merchantId: '',
    gameId: '',
    individualBetLimit: '',
    dailyLimit: '',
    monthlyLimit: '',
  });
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [gameList, setGameList] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  // Access the state object
  const { state } = location;

  // Extract specific values from state if needed
  const { id } = state || {};

  const getExposureDetails = async () => {
    try {
      const res = await exposureService.getExposure(id);
      if (res.code === 200) {
        const { data } = res;
        setFormValues({
          merchantId: data.merchantId?.name,
          gameId: data.gameId,
          individualBetLimit: data.individualBetLimit,
          dailyLimit: data.dailyLimit,
          monthlyLimit: data.monthlyLimit,
        });
      } else {
        throw new Error(res.message);
      }

    } catch (error) {
      console.error('Error fetching exposure details:', error);
      // toast.error('An error occurred while fetching the exposure details.');

    }
    finally {
      setLoading(false);
    }
  }

  const fetchData = async () => {
    try {
      const res = await gameService.getGameListDropdown();
      if (res.code === 200) {
        setGameList(res.data);
        // Set default gameId to "Cricket" if present
        // const defaultGame = res.data.find(game => game.name === 'Cricket');
        // if (defaultGame) {
        //   setFormValues(prev => ({
        //     ...prev,
        //     gameId: defaultGame.id,
        //   }));
        // }
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error('Error fetching game list:', error);
      // toast.error('An error occurred while fetching the game list.');
    }
  };


  useEffect(() => {

    getExposureDetails();
    fetchData();
  }, []);




  const validateForm = () => {
    const newErrors = {};

    // if (!formValues.merchantId) {
    //     newErrors.merchantId = 'Merchant ID is required.';
    // }
    if (!formValues.individualBetLimit || formValues.individualBetLimit <= 0) {
      newErrors.individualBetLimit = 'Individual Bet Limit must be greater than 0.';
    }
    if (!formValues.dailyLimit || formValues.dailyLimit <= 0) {
      newErrors.dailyLimit = 'Daily Limit must be greater than 0.';
    }
    if (!formValues.monthlyLimit || formValues.monthlyLimit <= 0) {
      newErrors.monthlyLimit = 'Monthly Limit must be greater than 0.';
    }

    setErrors(newErrors);
    setShowError(true);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors((prev) => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      if (!validateForm()) {
        return;
      }
      const payload = {
        ...formValues,
      };
      const formData = new FormData();
      formData.append('individualBetLimit', formValues.individualBetLimit);
      formData.append('dailyLimit', formValues.dailyLimit);
      formData.append('monthlyLimit', formValues.monthlyLimit);
      formData.append('gameId', formValues.gameId);

      const res = await exposureService.updateExposure(id,formData);

      if (res.code === 200) {
        toast.success(res.message);
        navigate('/exposure-management');
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error('Error adding exposure:', error);
      toast.error('An error occurred while adding the exposure.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className="align-items-center mb-4">
          <Card>
            <Card.Header>
               <div className="d-flex justify-content-between  text-end">
                            <h4 className="card-title ">View Exposure</h4>
              
                              <Button
                                variant="primary"
                                type="button"
                                className="ms-2"
                                onClick={() => navigate("/exposure-management")}
                              >
                                Back
                              </Button>
                              
                            </div>
            </Card.Header>
            <Card.Body>
              {loading ? <div className="d-flex mt-5 justify-content-center align-content-center">

                <BorderSpinner />
              </div> : <Container>
                <Form onSubmit={handleSubmit}>
                                      <Row>
                                      <Col md={6} className="mt-3">
                      <Form.Group controlId="gameId">
                        <Form.Label>Merchant</Form.Label>
                        <Form.Control
                          name="merchantId"
                          value={formValues.merchantId}
                    
                          disabled
                        />
                         
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mt-3">
                      <Form.Group controlId="gameId">
                        <Form.Label>Game</Form.Label>
                        <Form.Select
                          name="gameId"
                          value={formValues.gameId}
                          onChange={handleChange}
                          isInvalid={showError && !!errors.gameId}
                          disabled
                        >
                          <option value="">Select a game</option>
                          {gameList.map(game => (
                            <option key={game.id} value={game.id}>
                              {game.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.gameId}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mt-3">
                      <Form.Group controlId="individualBetLimit">
                        <Form.Label>Individual Payout Limit</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Individual Bet Limit"
                          name="individualBetLimit"
                          value={formValues.individualBetLimit}
                                                      onChange={handleChange}
                          disabled
                                                      
                          isInvalid={showError && !!errors.individualBetLimit}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.individualBetLimit}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mt-3">
                      <Form.Group controlId="dailyLimit">
                        <Form.Label>Daily Payout Limit</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Daily Limit"
                          name="dailyLimit"
                          value={formValues.dailyLimit}
                          onChange={handleChange}
                                                      isInvalid={showError && !!errors.dailyLimit}
                          disabled
                                                      
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.dailyLimit}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mt-3">
                      <Form.Group controlId="monthlyLimit">
                        <Form.Label>Monthly Payout Limit</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Monthly Limit"
                          name="monthlyLimit"
                          value={formValues.monthlyLimit}
                          onChange={handleChange}
                                                      isInvalid={showError && !!errors.monthlyLimit}
                          disabled
                                                      
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.monthlyLimit}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Row className="mt-4">
                    <Col>
                      <Button disabled={isSubmitting} type="submit" variant="primary" className="me-2">
                        {isSubmitting ? 'Please wait...' : 'Submit'}
                      </Button>
                      <Link to="/exposure-management" className="btn btn-primary">
                        Back
                      </Link>
                    </Col>
                  </Row> */}
                </Form>
              </Container>}
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewExposure;
