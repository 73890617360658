import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import MerchantService from "../../service/api/merchant.service";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import "./user.css";
import { Button, Card, Row, Col, Nav, ListGroup, Table, Badge, Form } from "react-bootstrap";
import PageBreadcrumb from "../../common/PageBreadcrum";

import "cryptofont/css/cryptofont.css";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";
import globalSwal from "../../common/swalService";
import Modal from "react-bootstrap/Modal";
import Select from "react-select"; // For multiselect input
import { darkModeStyles, lightModeStyles } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";
import GameService from "../../service/api/game.service";
import GameSettingService from "../../service/api/game-setting.service";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import AddAmountModal from "./addAmount";
import { BorderSpinner } from "../../common/Spinner";
import WithdrawAmountModal from "./withdrawAmount";
import AssignGames from "./AssignGames";

export default function MerchantList() {
  const merchantService = MerchantService();
  const authService = AuthService();
  const gameService = GameService();
  const gameSettingService = GameSettingService();
  const navigate = useNavigate();
  const { skin, setSkin } = useSkinSwitcher();
  const [merchantList, setMerchantList] = useState([]);
  
  const [pagination, setPagination] = useState({
    page: 1,
    searchBy: '',
    limit: 10,
    totalDocs: 0
  });


  const [showAssignGameModal, setShowAssignGameModal] = useState(false);
  const [selectedMerchantId, setSelectedMerchantId] = useState(null);

  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [passwordForm, setPasswordForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [validationErrors, setValidationErrors] = useState({});



  const [columns, setColumns] = useState(["name", 'email', 'phone', 'supportNumber', "depositeNumber", "status", "id"]);
  const [showAddAmountModal, setShowAddAmountModal] = useState(false);
  const [showWithdrawAmountModal, setShowWithdrawAmountModal] = useState(false);

  // const handleOpenModal = () => setShowModal(true);
  // const handleCloseModal = () => setShowModal(false);
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  useEffect(() => {
    getList();
    authService.getLoginData()
  }, [pagination.searchBy, pagination.page]);

  const getList = async () => {
    const res = await merchantService.getMerchants({
      page: pagination?.page,
      limit: pagination?.limit,
      searchBy: pagination?.searchBy
    });
    if (res?.code === 200) {
      setMerchantList(res?.data?.docs);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pages: res.data.pages,
        totalDocs: res.data.totalDocs,
        limit: res.data.limit
      }));
    } else {
      console.error(res?.message);
      // toast.error(res?.message);
    }
  };


  // useEffect(() => {
  //   setSelectedCategories((prevValue) =>
  //     prevValue.map((selectedCategory) => {
  //       // Find the matching category in categoryList
  //       const matchingCategory = categoryList.find(
  //         (category) => category.value === selectedCategory.value
  //       );

  //       // If a matching category is found, replace its games array
  //       return {
  //         ...selectedCategory,
  //         games: matchingCategory ? matchingCategory.games : selectedCategory.games,
  //       };
  //     })
  //   );
  // }, [selectedCategories]);

  useEffect(() => {
    fetchGameCategories();
  }, [])

  // Define HTML for status badges
  const getStatusBadge = (status) => {
    if (status === 1) {
      return <Badge bg={'success'} className="badge badge-success">Active</Badge>;
    } else if (status === 0) {
      return <Badge bg={'pink'} className="badge badge-danger">Inactive</Badge>;
    }
  };
  const validatePasswordForm = () => {
    const errors = {};
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/;

    const { newPassword, confirmPassword } = passwordForm;

    if (!newPassword || newPassword.length < 8) {
      errors.newPassword = "Password must be at least 8 characters long.";
    }

    if (newPassword && !passwordRegex.test(newPassword?.trim())) {
      errors.newPassword = "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character";

    }

    if (newPassword !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Define HTML for action column
  const getActionDropdown = (id, status,moduleId) => {
    console.log("id", id);
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
        <Dropdown.Item disabled={!authService.checkAccess(moduleId,'view')} onClick={() => handleView(id)}>View</Dropdown.Item>
        <Dropdown.Item disabled={!authService.checkAccess(moduleId,'edit')} onClick={() => handleUpdate(id)}>Edit</Dropdown.Item>
          <Dropdown.Item  onClick={() => handleAddAmount(id)}>
            Add Amount
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleWithdrawAmount(id)}>
            Withdraw Amount
          </Dropdown.Item>
          <Dropdown.Item  onClick={() => handleAssignGames(id)}>
            Assign Games
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleResetPassword(id)}>
            Reset Password
          </Dropdown.Item>
         
          <Dropdown.Item disabled={!authService.checkAccess(moduleId,'edit')} onClick={() => handleStatusUpdate(id)}>
            {/* Activate/Deactivate */}
            {status === 1 ? "Deactivate" : "Activate"}
          </Dropdown.Item>
          <Dropdown.Item disabled={!authService.checkAccess(moduleId,'delete')} onClick={() => handleDelete(id)}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };



  const fetchGameCategories = async () => {
    // const res = await merchantService.getGameCategories();
    // if (res?.code === 200) {
    //   setGameCategories(
    //     res?.data?.map((category) => ({
    //       label: category.name,
    //       value: category.id,
    //       games: category.games.map((game) => ({
    //         label: game.name,
    //         value: game.id,
    //       })),
    //     }))
    //   );
    // }
  };


  const handleUpdate = (id) => {
    const state = { id: id };
    navigate(`/merchant-management/edit`, { state });
  };

  const handleView = (id) => {
    const state = { id: id };
    navigate(`/merchant-management/view`, { state });
  };

  const handleStatusUpdate = async (id) => {
    const merchant = merchantList.filter((item) => item.id === id);

    globalSwal.confirm({
      title: "Are you sure?",
      text: "Do you really want to change the current status?",
      onConfirm: async () => {


        const res = await merchantService.updateMerchantStatus(merchant[0].id, {
          status: merchant[0].status == 1 ? 0 : 1,
        });
        if (res?.code === 200) {
          globalSwal.success({
            title: "Success!",
            text: "Status has been changed successfully.",
          });
          toast.success(res?.message || "Status updated successfully.");
          getList();
        } else {
          toast.error(res?.message || "Failed to update status.");
        }

      },
    });


  };

  const handleDelete = async (id) => {
    // const state = {id: id}
    // navigate(`/role-management/edit`, {state})
    globalSwal.confirm({
      title: "Are you sure?",
      text: "Do you really want to delete?",
      onConfirm: async () => {


        const res = await merchantService.deleteStaff(id);
        if (res?.code === 200) {
          globalSwal.success({
            title: "Success!",
            text: "Merchant has been deleted successfully.",
          });
          toast.success(res?.message);
          getList();
          setPagination({ ...pagination, page: 1 })
        } else {
          toast.error(res?.message || "Failed to delete.");
        }

      },
    });

  };
  // add amount

  const handleAddAmount = (id) => {
    setSelectedMerchantId(id);
    setShowAddAmountModal(true);
  };
  // withdraw amount
  const handleWithdrawAmount = (id) => {
    setSelectedMerchantId(id);
    setShowWithdrawAmountModal(true);
  }

  // assign game

  const handleAssignGames = (id) => {
    // Fetch game categories dynamically
    setSelectedMerchantId(id);
    // getAssignedGames(id);
    setShowAssignGameModal(true);
  };
  // reset password
  const handleResetPassword = (id) => {
    setSelectedMerchantId(id);
    setPasswordForm({ oldPassword: "", newPassword: "", confirmPassword: "" });
    setValidationErrors({});
    setShowResetPasswordModal(true);
  };


  const handleSubmitResetPassword = async () => {
    if (!validatePasswordForm()) return;

    try {
      const res = await merchantService.updateMerchantStatus(selectedMerchantId, {
        password: passwordForm.newPassword,
      });

      if (res?.code === 200) {
        toast.success("Password reset successfully!");
        setShowResetPasswordModal(false);
      } else {
        toast.error(res?.message || "Failed to reset password.");
      }
    } catch (error) {
      toast.error("An error occurred while resetting the password.");
    }
  };

  // game assignment functionality




  // Map the columns to include HTML for status badges
  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "status") {
      return {
        header: "Status",
        accessor: column,
        Cell: ({ value }) => getStatusBadge(value), // Render status badge based on the value
      };
    } else if (column.toLowerCase() === "id") {
      return {
        header: " ",
        accessor: column,
        Cell: ({ value, row }) => getActionDropdown(value, row?.status,5), // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "name") {
      return {
        header: "Name",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "email") {
      return {
        header: "Email",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "phone") {
      return {
        header: "Phone Number",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "supportnumber") {
      return {
        header: "Support Mobile Number",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "depositenumber") {
      return {
        header: "Deposit Mobile Number",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else {
      return column;
    }
  });



  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.trim();
    // if (value.length > 3 || value.length === 0) {
    // }
    setPagination((prev) => ({ ...prev, searchBy: value }));
  };



  const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);
  return (
    <React.Fragment>
      {/* add amount modal */}
      <AddAmountModal
        show={showAddAmountModal}
        onClose={() => {
          setShowAddAmountModal(false);
        }}
        merchantId={selectedMerchantId} // Example Merchant ID
      // fetchWalletData={fetchWalletData}
      />
      <WithdrawAmountModal
        show={showWithdrawAmountModal}
        onClose={() => {
          setShowWithdrawAmountModal(false);
        }}
        merchantId={selectedMerchantId} // Example Merchant ID
      // fetchWalletData={fetchWalletData}
      />

      <AssignGames
        show={showAssignGameModal}
        onClose={() => {
          setShowAssignGameModal(false);
        }}
        merchantId={selectedMerchantId} // Example Merchant ID
      // fetchWalletData={fetchWalletData}
      />
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>

          <div className=" align-items-center">
            <Card.Header>

              <PageBreadcrumb title="Merchants" />

            </Card.Header>
            <TableWithPagination
              data={[...merchantList]}
              itemsPerPage={pagination?.limit}
              columns={columnsWithHTML}
              currentPage={pagination?.page}
              totalPages1={pagination?.totalPages}
              totalData={pagination?.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              moduleId={5}
              redirectUrl={"/merchant-management/add"}
              handleSearchChange={debouncedHandleSearchChange}
            />
          </div>
        </Card>
      </div>
      {/* <Modal
        show={showAssignGameModal}
        onHide={() => setShowAssignGameModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Games to Merchant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isGameLoading ? <>
            <div className="d-flex mt-5 justify-content-center align-content-center">
              <BorderSpinner />
            </div>
          </> : (<>
            <div className="mb-3">
            <label>Game Categories</label>
            <Select
              options={categoryList}
              isMulti
              value={selectedCategories}
              styles={theme === 'dark' ? darkModeStyles : lightModeStyles}
              placeholder={"Select catagories..."}
             
              onChange={handleCategoryChange}
            />
            {!selectedCategories.length && (
              <small className="text-danger">Please select at least one category.</small>
            )}
          </div>
          <div className="mb-3">
            <label>Games</label>
            <Select
              styles={theme === 'dark' ? darkModeStyles : lightModeStyles}
              placeholder={"Select games..."}
              options={selectedCategories.flatMap((cat) => cat.games)}
              isMulti
              value={selectedGames}
              onChange={handleGameChange}
            />
            {!selectedGames.length && (
              <small className="text-danger">Please select at least one game.</small>
            )}
          </div>
          </>)}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isAssigned} variant="secondary" onClick={() => setShowAssignGameModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={ () => {
              if (validateInputs()) {
                 assignGamesToMerchant();
              }
            }}
            disabled={isAssigned || !validateInputs()}
           
          >
            {!isAssigned ? 'Assign' : 'Pleas Wait...'}
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/* Reset password */}
      <Modal show={showResetPasswordModal} onHide={() => setShowResetPasswordModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" style={{ position: "relative" }}>
              <Form.Label>New Password</Form.Label>
              <div style={{ position: "relative" }}>
                <Form.Control
                  type={showPassword.newPassword ? "text" : "password"}
                  placeholder="Enter new password"
                  value={passwordForm.newPassword}
                  isInvalid={!!validationErrors.newPassword}
                  onChange={(e) => setPasswordForm({ ...passwordForm, newPassword: e.target.value })}
                />
                <span
                  onClick={() => togglePasswordVisibility("newPassword")}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "#6c757d",
                  }}
                >
                  {showPassword.newPassword ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                </span>
              </div>
              <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                {validationErrors.newPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" style={{ position: "relative" }}>
              <Form.Label>Confirm Password</Form.Label>
              <div style={{ position: "relative" }}>
                <Form.Control
                  type={showPassword.confirmPassword ? "text" : "password"}
                  placeholder="Confirm new password"
                  value={passwordForm.confirmPassword}
                  isInvalid={!!validationErrors.confirmPassword}
                  onChange={(e) => setPasswordForm({ ...passwordForm, confirmPassword: e.target.value })}
                />
                <span
                  onClick={() => togglePasswordVisibility("confirmPassword")}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "#6c757d",
                  }}
                >
                  {showPassword.confirmPassword ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                </span>
              </div>
              <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                {validationErrors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowResetPasswordModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmitResetPassword}>
            Reset Password
          </Button>
        </Modal.Footer>
      </Modal>


    </React.Fragment>
  );
}
