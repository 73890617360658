import React, { useEffect, useRef, useState, useCallback } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
// import StaffService from "../../service/api/staff.service";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import "./sports.css";
import _ from 'lodash';
import PageBreadcrumb from "../../common/PageBreadcrum";

import {
    Button,
    Card,
    Row,
    Col,
    Nav,
    ListGroup,
    Table,
    Badge,
    Modal,
    Form,
} from "react-bootstrap";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";
import CricketSportService from "../../service/api/sport.service";
// import { data } from "jquery";
import globalSwal from "../../common/swalService";
// import moment from "moment/moment";
import formatDateTime from "../../helper/DateFormate";
export default function CricketHistory() {
    const cricketSportService = CricketSportService();
    // const authService = AuthService();
    // const navigate = useNavigate();
    const { skin, setSkin } = useSkinSwitcher();
    const [cricketList, setCricketList] = useState([]);
    const [runnerJson, setRunnerJson] = useState([]);
    const [runnerOptions, setRunnerOptions] = useState([]);
    const location = useLocation();
    const [pagination, setPagination] = useState({
        searchBy: location.state?.match_id || "",
        page: 1,
        limit: 10,
    });
    const [columns, setColumns] = useState([
        "match_id",
        "seriesName",
        "matchType",
        "start_date",
        "InplayStatus",
        // "IsBetAllow",
        // "IsFancyAllow",
        // "BetAllowTimeBefore",
        "totalBet",
        "totalBetAmount",
        "winner",
        "id",
    ]);

    // New state variables for Add Amount Modal
    const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
    const [updateStatusForm, setUpdateStatusForm] = useState({
        matchId: "",
        winningSelectionId: ""
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    

    const match_id = location.state?.match_id;

    useEffect(() => {
        if (location.state?.match_id) {
            setPagination((prevPagination) => ({
                ...prevPagination,
                searchBy: location.state?.match_id,
            }));
        }
    }, [location.state?.match_id ])


    const getList = useCallback(_.debounce(async () => {
        const res = await cricketSportService.getCricketSportHistory({
            page: pagination?.page,
            limit: pagination?.limit,
            searchBy: pagination?.searchBy,
        });
        if (res?.status === 200) {
            setCricketList(res?.data?.docs);
            setPagination((prevPagination) => ({
                ...prevPagination,
                pages: res.data.pages,
                totalDocs: res.data.totalDocs,
                limit: res.data.limit
            }));
        } else {
            console.error(res?.message);
            // toast.error(res?.message);
        }
    }, 300), [pagination.page, pagination.limit, pagination.searchBy]);

    useEffect(() => {
        getList();
        
        // Cleanup function to cancel pending debounced calls
        return () => {
            getList.cancel();
        };
    }, [pagination.page, pagination.limit, pagination.searchBy]);



    // Define HTML for status badges
    const getStatusBadge = (status) => {
        if (status === 'OPEN') {
            return (
                <Badge bg={"success"} className="badge badge-success">
                    {status}
                </Badge>
            );
        } else if (status === 'CLOSED') {
            return (
                <Badge bg={"pink"} className="badge badge-danger">
                    {status}
                </Badge>
            );
        }
        else if (status === 'SUSPENDED') {
            return (
                <Badge bg={"secondary"} className="badge badge-danger">
                    {status}
                </Badge>
            );
        }
        else {
            return (
                <Badge bg={"primary"} className="badge badge-danger">
                    {'N/A'}
                </Badge>
            )
        }
    };

    // Define HTML for action column
    const getActionDropdown = (id, data) => {
        console.log("action============", data, id);
        const findWinner = (runners) => {
            const winner = runners.find(runner => runner.winner === true);
            return winner ;
        }
        const winner = findWinner(data.runners); 
        const matchTypes=['Inplay','Upcoming']
        return (
            <Dropdown>
                <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
                    <i className="ri-more-2-fill"></i>
                </Dropdown.Toggle>
                {/* matchTypes.includes(data.matchType) || */}

                {/* matchTypes.includes(data.matchType)?()=>{} : */}
                {/* || data.matchType==='Upcoming'  */}

                {/* || matchTypes.includes(data.matchType)  */}
                <Dropdown.Menu>
                    <Dropdown.Item disabled={ winner  }  onClick={() => handleStatusUpdate(id, data)}>
                        Update Status
                    </Dropdown.Item>
                     {/* <Dropdown.Item disabled={false }  onClick={() => handleStatusUpdate(id, data)}>
                        Update Status
                    </Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown>
        );
    };



    const handleStatusUpdate = (id, data) => {
        setUpdateStatusForm({
            matchId: data?.match_id,
            winningSelectionId: "",

        });
        setRunnerOptions(data?.runners);
        const formattedRunners = data?.runners?.reduce((result, runner) => {
            if (runner.marketName === "MatchDetails") {
                result.push({
                    selectionId: runner.selectionName,
                    selectionName: runner.selectionName, // Use the same name for MatchDetails
                });
            } else if (runner.marketName === "OtherMarketList" && !result.some(r => r.selectionName === "Tied")) {
                // Ensure only one "The Draw" entry is added
                result.push({
                    selectionName: "Tied",
                    selectionId: "Yes",
                });
            }
            return result;
        }, []);


        setRunnerJson(formattedRunners)
        setShowUpdateStatusModal(true);
    };

    // Handle Add Amount Form Change
    const handleAddAmountChange = (e) => {
        const { name, value } = e.target;
        setUpdateStatusForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const handleAddAmountSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setShowUpdateStatusModal(false);
        const { matchId, winningSelectionId } = updateStatusForm;
        const formattedRunners = runnerOptions?.reduce((result, runner) => {
            if (runner.selectionName ===winningSelectionId) {
                result.push({
                    selectionId: runner.selectionName,
                    marketName:runner.marketName,
                    selectionName: runner.selectionName, // Use the same name for MatchDetails
                });
            }
            return result;
        }, []);

        // Basic validation
        if (!winningSelectionId || !matchId) {
            toast.error("Please fill in all required fields.");
            setIsSubmitting(false);
            return;
        }

        try {

            globalSwal.confirm({
                title: "Are you sure?",
                text: "Do you really want to change the current status?",
                onConfirm: async () => {
                    const res = await cricketSportService.updateStatus({
                        matchId,
                        winnerJson:formattedRunners,
                    });
                    if (res?.code === 200) {
                        toast.success(res?.message || "Status Updated successfully.");
                        setShowUpdateStatusModal(false);
                        globalSwal.success({
                            title: "Success!",
                            text: "Status has been changed successfully.",
                        });
                        getList();
                    } else {
                        toast.error(res?.message || "Failed to update status.");
                    }

                },
            });


        } catch (error) {
            console.error("Error updating status:", error);
            toast.error("An unexpected error occurred while updating match status.");
        } finally {
            setIsSubmitting(false);
        }
    };

    

    // Map the columns to include HTML for status badges
    const columnsWithHTML = columns.map((column) => {
        if (column.toLowerCase() === "seriesname") {
            return {
                header: "Series Name",
                accessor: column,
                Cell: ({ row }) => (
                    <div>
                        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                            {row.name || 'N/A'}
                        </div>
                        <div className="bg-secondary text-white p-1 ps-4  rounded-pill" style={{ fontSize: '0.875rem', color: '#666', width: '300px' }}>
                            {row.seriesName || 'N/A'}
                        </div>
                    </div>
                ),
            };
        } else if (column.toLowerCase() === "id") {
            return {
                header: " ",
                accessor: column,
                Cell: ({ value, row }) => getActionDropdown(value, row), // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "inplaystatus") {
            return {
                header: "Inplay Status",
                accessor: column,
                Cell: ({ value }) => getStatusBadge(value), // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "matchtype") {
            return {
                header: "Match Type",
                accessor: column,
                Cell: ({ value }) => (<div
                    className={`matchTypeCell ${!value ? 'na' :` ${value}`}`}
                  >
                   <span className="text"> {value || "N/A"}</span>
                  </div>)
            };
        }
        // else if (column.toLowerCase() === "isbetallow") {
        //     return {
        //         header: "Bet Allowed",
        //         accessor: column,
        //         Cell: ({ value }) => value == 'Y' ? 'Yes' : 'No', // Render action dropdown based on the value
        //     };
        // }
        // else if (column.toLowerCase() === "isfancyallow") {
        //     return {
        //         header: "Fancy Betting",
        //         accessor: column,
        //         Cell: ({ value }) => value == 'Y' ? 'Yes' : 'No', // Render action dropdown based on the value
        //     };
        // }
        else if (column.toLowerCase() === "totalbet") {
            return {
                header: "Total Bet",
                accessor: column,
                Cell: ({ value }) => {
                    const totalMatched = value ?? 'N/A';
                    return totalMatched;
                }, // Display totalMatched from runner_json
            };
        }
        else if (column.toLowerCase() === "winner") {
            return {
                header: "Winner",
                accessor: column,
                Cell: ({ row }) => {
                    const findWinner = (runners) => {
                        const winner = runners.find(runner => runner.winner === true);
                        return winner ? winner.selectionName==='Yes'?"Tied":winner.selectionName: "Pending";
                    }
                    const winner = findWinner(row.runners); // Call function to get the winner

                    // Display the result with Bootstrap styling
                    return (
                      <div className="text-center">
                        <Badge
                          bg={winner === "Pending" ? "secondary" : "success"} // Change color based on winner status
                          className="p-2" // Add padding to make it look better
                        >
                          {winner}
                        </Badge>
                      </div>
                    );
                }, // Display totalMatched from runner_json
            };
        }
        
        else if (column.toLowerCase() === "totalbetamount") {
            return {
                header: "Total Bet Amount",
                accessor: column,
                Cell: ({ value }) => value?? 'N/A', // Display matchVolumn
            };
        }
        else if (column.toLowerCase() === "start_date") {
            return {
                header: "Start Date",
                accessor: column,
                Cell: ({ value }) => {
                    if (!value) return 'N/A'; // Handle null or undefined values

                    // Use moment to format the date and time
                    // const formattedDate = moment(1725460200).format('MMMM D, YYYY h:mm A');
                    //    return formattedDate
                    return (
                        <span className="text-nowrap">{formatDateTime(Number(value) * 1000)}</span>
                    ) // Return formatted date and time
                }
            };
        }
        else if (column.toLowerCase() === "match_id") {
            return {
                header: "Match Id",
                accessor: column,
                Cell: ({ value }) => {
                    if (!value) return 'N/A'; // Handle null or undefined values

                    // Use moment to format the date and time
                    // const formattedDate = moment(1725460200).format('MMMM D, YYYY h:mm A');
                    //    return formattedDate
                    return value || "N/A"
                }
            };
        }
    });



    const useDebounce = (func, delay) => {
        const debounceRef = useRef(null);

        return (...args) => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
            debounceRef.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };
    const debouncedSearch = useCallback(_.debounce((value) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            searchBy: value,
        }));
    }, 300), []);
    const handleSearchChange = (event) => {
        console.log("searchBy==")
        const value = event.target.value.trim();
        // if (value.length > 3 || value.length === 0) {
        debouncedSearch(value);
        // }
    };

    // const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);


    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <Card>

                    <div className=" align-items-center">
                        <Card.Header>

                            <PageBreadcrumb title="Cricket Sports" />

                        </Card.Header>
                        <TableWithPagination
                            data={[...cricketList]}
                            itemsPerPage={pagination?.limit}
                            columns={columnsWithHTML}
                            currentPage={pagination?.page}
                            totalPages1={pagination?.totalPages}
                            totalData={pagination?.totalDocs}
                            pagination={pagination}
                            setPagination={setPagination}
                            redirectUrl={""}
                            moduleId={11}
                            tableType={"transaction"}

                            handleSearchChange={handleSearchChange}
                        />
                    </div>
                </Card>
                <Modal
                    show={showUpdateStatusModal}
                    onHide={() => setShowUpdateStatusModal(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Status</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleAddAmountSubmit}>
                        <Modal.Body>
                            <Form.Group controlId="formMatchId">
                                <Form.Label>Match ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="matchId"
                                    value={updateStatusForm.matchId}
                                    readOnly
                                />
                            </Form.Group>

                            <Form.Group controlId="formWinningSelectionId" className="mt-3">
                                <Form.Label>Winner</Form.Label>
                                <Form.Select
                                    name="winningSelectionId"
                                    value={updateStatusForm.winningSelectionId}
                                    onChange={handleAddAmountChange}
                                    required
                                >
                                    <option value="">Select Winner</option>
                                    {runnerJson?.map((item) => (
                                        <option key={item?.selectionId} value={item?.selectionId}>
                                            {item?.selectionName}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            {/* <Form.Group controlId="formAmount" className="mt-3">
                                <Form.Label>Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="amount"
                                    value={updateStatusForm.amount}
                                    onChange={handleAddAmountChange}
                                    min="0"
                                    max="99999999"
                                    step="0.01"
                                    placeholder="Enter amount"
                                    required
                                    onKeyDown={(e) => {
                                        // Prevent entering '+' or '-' symbols
                                        if (e.key === '+' || e.key === '-') {
                                            e.preventDefault();
                                        }
                                    }
                                    }
                                />
                            </Form.Group> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => setShowUpdateStatusModal(false)}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                                {isSubmitting ? "Submitting..." : "Update Status"}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        </React.Fragment>
    );
}
