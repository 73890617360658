// apiService.js

import axios from "axios";
// import { environment } from '../environment/environment.prod'; // Correcting the import path
import logout from "./api/auth.service"; // Assuming you have an authService with a logout function
import AuthService from "../service/api/auth.service";
import { toast } from "react-toastify";
let hasShownToast = false; // Track if toast has already been shown

const createAxiosInstance = (bearerToken,type) => {
  // Create a reusable Axios instance with the provided bearer token
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Base URL for your API
    headers: {
      Authorization: `${type?bearerToken:'Bearer '+bearerToken}`, // Bearer token header
      // Add other common headers here if needed
    },
  });

  // Add response interceptor to handle 401 errors
  instance.interceptors.response.use(
    (response) => {
      if (response?.data?.code === 401 && !hasShownToast) {
        // localStorage.clear();
        console.log(response)
        hasShownToast = true;
        // toast.error("Session expired. Please sign in again.")
        toast.error(response.data.message)

        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/"; // Redirect to sign-in page
        }, 3000); // 3 seconds delay
        return;
      }
      return response;
    },
    (error) => {
      const response=error.response;
      if (response?.data?.code === 401 && !hasShownToast) {
        // localStorage.clear();
        hasShownToast = true;
        // toast.error("Session expired. Please sign in again.")
        toast.error(response.data.message)

        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/"; // Redirect to sign-in page
        }, 3000); // 3 seconds delay
       
      }
      console.log("error", error);
      return error?.response?.data;
      // if (error.response && error.response.status === 401) {
      //   // logout(); // Call logout function on 401 error
      // }
      // return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance;
