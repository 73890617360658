import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import LotteryService from "../../service/api/lottery.service";
import CurrencyService from "../../service/api/currency.service";
import { format } from "date-fns";
import { Link, useLocation, useParams } from "react-router-dom";
import { BorderSpinner } from "../../common/Spinner";
import InfoComponent from "./Info";

const ViewLottery = () => {
    const { skin, setSkin } = useSkinSwitcher();
    const lotteryService = LotteryService();
    const currencyService = CurrencyService();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const { id } = location.state;
    const [currencyList, setCurrencyList] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        type: "",
        frequency: "",
        nextDraw: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
        cutOffTime: "",
        ticketPrice: "",
        currencyId: "",
        adminProfitPercent: "",
        prizePool: "",
        payoutMethod: "",
        isActive: true,
        prizeDistribution: {
            jackpotPercent: "",
            fiveMatchPercent: "",
            fourMatchPercent: "",
            threeMatchPercent: "",
            twoMatchPercent: "",
            oneMatchPercent: "",
        },
        defaultPrizes: [{ prizeType: "cash", prizeValue: "", prizeDescription: "" }],
    });
    // const { id } = useParams(); // Assuming you're getting lottery ID from the URL

    const getCurrencyList = async () => {
        try {
            const res = await currencyService.getCurrencyList({ page: 1, limit: 20, searchBy: '' });
            if (res?.code === 200) {
                setCurrencyList(res?.data?.docs);
            }
        } catch (error) {
            console.error("Error fetching currency list:", error);
        }
    };

    const getLotteryInfo = async () => {
        try {
            const res = await lotteryService.getLotteryById(id);
            if (res?.code === 200) {
                const lotteryData = res.data;

                // Format the cutOffTime to "yyyy-MM-dd'T'HH:mm"
                const formattedCutOffTime = format(new Date(lotteryData.cutOffTime), "yyyy-MM-dd hh:mm a");
                const formattedNextDrawTime = format(new Date(lotteryData.nextDraw), "yyyy-MM-dd hh:mm a");
                const formattedEndTime = format(new Date(lotteryData.endDate), "yyyy-MM-dd hh:mm a");
                const formattedStartDate = format(new Date(lotteryData.startDate), "yyyy-MM-dd hh:mm a");

                // Set the form data with the formatted cutOffTime
                setFormData({
                    ...lotteryData,
                    cutOffTime: formattedCutOffTime,
                    nextDraw: formattedNextDrawTime,
                    endDate: formattedEndTime,
                    startDate: formattedStartDate,


                });
            }
        } catch (error) {
            console.error("Error fetching lottery details:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCurrencyList();
        getLotteryInfo();
    }, []);

    if (isLoading) {
        return <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4  ">
                <div className="d-flex mt-5 justify-content-center align-content-center">

                    <BorderSpinner />
                </div>

            </div>
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className="align-items-center mb-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between  text-end">
                                <h4 className="card-title ">View Lottery Details</h4>

                                {/* <Button
                                    variant="primary"
                                    type="button"
                                    className="ms-2"
                                    onClick={() => navigate("/user-management")}
                                >
                                    Back
                                </Button> */}
                                <Link to="/lottery-list" className="btn btn-primary ms-2">
                                    Back
                                </Link>
                            </div>
                        </Card.Header>
                        {/* <Card.Header>
                            <h4 className="card-title w-100">View Lottery Details</h4>
                        </Card.Header> */}
                        <Card.Body>
                            <Form className="row">
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <InfoComponent message={`Provide a unique name for this lottery (e.g., 'Weekly Super Jackpot' or 'Monthly Mega Win'). This helps in identifying the lottery type for users.`} />
                                      
                                        <Form.Control type="text" value={formData.name} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="type">
                                        <Form.Label>Type</Form.Label>
                                        <InfoComponent message={`Select the type of lottery, such as 'Daily', 'Weekly', or 'Monthly'. Each type may have different rules or prize structures based on your settings.`} />

                                        <Form.Control type="text" value={formData.type} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="frequency">
                                        <Form.Label>Frequency</Form.Label>
                                        <InfoComponent message={`Select how often this lottery will occur. Options could include 'Daily', 'Weekly' or 'Monthly'. The frequency determines how often a draw is held.`} />

                                        <Form.Control type="text" value={formData.frequency} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="nextDraw">
                                        <Form.Label>Next Draw</Form.Label>
                                        <InfoComponent message={`Set the date and time for the next draw. This is the scheduled moment when the winning numbers are announced.`} />

                                        <Form.Control type="text" value={formData.nextDraw} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="cutOffTime">
                                        <Form.Label>Cut-Off Time</Form.Label>
                                        <InfoComponent message={`Specify the cut-off time for ticket sales, usually a few hours before the draw to allow time for preparation. Ensure this is clear to users, as tickets will be sold up to this time. For example, if the draw is at 9:00 PM, the cut-off time might be 8:00 PM.`} />

                                        <Form.Control type="text" value={formData.cutOffTime} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="cutOffTime">
                                        <Form.Label>Start Date</Form.Label>
                                        <InfoComponent message={`Enter the date when this lottery officially begins. This is when users can start purchasing tickets.`} />

                                        <Form.Control type="text" value={formData.startDate} readOnly />
                                    </Form.Group>
                                </Col> <Col md={4} className="mt-3">
                                    <Form.Group controlId="cutOffTime">
                                        <Form.Label>End Date</Form.Label>
                                        <InfoComponent message={`Specify the date when this lottery ends.`} />

                                        <Form.Control type="text" value={formData.endDate} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="ticketPrice">
                                        <Form.Label>Ticket Price</Form.Label>
                                        <InfoComponent message={`Enter the cost of a single ticket in the selected currency. This is the price users will pay per entry. Be sure to set this based on your target audience and the expected prize pool.`} />

                                        <Form.Control type="text" value={formData.ticketPrice} readOnly />
                                    </Form.Group>
                                </Col>

                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="currencyId">
                                        <Form.Label>Currency</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={formData.currencyId?.currencyCodes}

                                            // value={currencyList.find((c) => c.id === formData.currencyId)?.currencyCodes || ""}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="adminProfitPercent">
                                        <Form.Label>Admin Profit %</Form.Label>
                                        <InfoComponent message={`Enter the percentage of each ticket sale allocated as profit for the admin. For instance, a 5% admin profit means 5% of the ticket sales revenue goes to the admin, and the rest contributes to the prize pool.`} />

                                        <Form.Control type="text" value={formData.adminProfitPercent} readOnly />
                                    </Form.Group>
                                </Col>
                                {/* <Col md={4} className="mt-3">
                                    <Form.Group controlId="prizePool">
                                        <Form.Label>Prize Pool</Form.Label>
                                        <InfoComponent message={`Specify the total amount of money available in the prize pool. This could be a fixed amount or an accumulated amount based on ticket sales.`} />

                                        <Form.Control type="text" value={formData.prizePool} readOnly />
                                    </Form.Group>
                                </Col> */}

                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="payoutMethod">
                                        <Form.Label>Payout Method</Form.Label>
                                        <Form.Control type="text" value={formData.payoutMethod} readOnly />
                                    </Form.Group>
                                </Col>

                                {/* Prize Distribution */}
                                <Col md={12} className="mt-5 mb-0">
                                    <h5>Prize Distribution</h5>
                                </Col>
                                {["jackpotPercent", "fiveMatchPercent", "fourMatchPercent", "threeMatchPercent", "twoMatchPercent", "oneMatchPercent"].map((category, index) => (
                                    <Col md={4} className="mt-3" key={index}>
                                        <Form.Group controlId={category}>
                                            <Form.Label>
                                                {category
                                                    .replace("Percent", " ")
                                                    .replace("Match", " Match")
                                                    .trim()
                                                    .charAt(0).toUpperCase() + category
                                                        .replace("Percent", " ")
                                                        .replace("Match", " Match")
                                                        .trim()
                                                        .slice(1)} %
                                            </Form.Label>
                                            {index == 0 && <InfoComponent message={`Enter the percentage of the prize pool that will be awarded to the jackpot winner (the player who matches all numbers). For example, 50% means half of the prize pool is given as the jackpot.`} />}

                                            <Form.Control
                                                type="text"
                                                value={formData.prizeDistribution[category]}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>
                                ))}

                                {/* Default Prizes */}
                                <Col md={12} className="mt-5 mb-0">
                                    <h5>Default Prizes</h5>
                                    {formData.defaultPrizes.map((prize, index) => (
                                        <div key={index} className={`row p-2 mb-3`}>
                                            <Col md={3}>
                                                <Form.Group controlId={`prizeType-${index}`}>
                                                    <Form.Label>Prize Type</Form.Label>
                                                    <Form.Control type="text" value={prize.prizeType} readOnly />
                                                </Form.Group>
                                            </Col>

                                            {prize.prizeType === 'other' && (
                                                <Col md={3}>
                                                    <Form.Group controlId={`defaultPrize-${index}-customPrizeType`}>
                                                        <Form.Label>Other Prize Type</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                           
                                                            value={prize.customPrizeType || ''}
                                                            readOnly
                                                        />

                                                    </Form.Group>
                                                </Col>
                                            )}
                                            <Col md={3}>
                                                <Form.Group controlId={`prizeValue-${index}`}>
                                                    <Form.Label>Prize Value</Form.Label>
                                                    <Form.Control type="text" value={prize.prizeValue} readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId={`prizeDescription-${index}`}>
                                                    <Form.Label>Prize Description</Form.Label>
                                                    <Form.Control type="text" value={prize.prizeDescription} readOnly />
                                                </Form.Group>
                                            </Col>
                                        </div>
                                    ))}
                                </Col>

                                <Col md={12} className="mt-3">
                                    <h5>Winning Numbers</h5>
                                    {formData?.winningNumbers?.length >= 0 && <div style={{ display: 'flex', gap: '8px', color: '#333', fontWeight: 'bold' }}>
                                        {formData?.winningNumbers?.map((num, index) => (
                                            <span className="text-center"
                                                key={index}
                                                style={{
                                                    
                                                    minWidth:'40px',
                                                    padding: '4px 8px',
                                                    backgroundColor: '#5CDA86',
                                                    borderRadius: '4px',
                                                    color: '#333',

                                                }}
                                            >
                                                {num}
                                            </span>
                                        ))}
                                        {formData?.jackpotNumber > 0 && <span
                                            className="text-center"
                                            style={{
                                                minWidth:'40px',
                                                padding: '4px 8px',
                                                backgroundColor: '#5CDA86',
                                                borderRadius: '4px',
                                                color: '#333',
                                                border: '2px solid green'
                                            }}
                                        >
                                            {formData?.jackpotNumber}
                                        </span>}
                                    </div>}
                                    {
                                        formData?.winningNumbers?.length === 0 && <div style={{ display: 'flex', gap: '8px', color: '#333', fontWeight: 'bold' }}>

                                            <span

                                                style={{
                                                    padding: '4px 8px',
                                                    backgroundColor: '#5CDA86',
                                                    borderRadius: '4px',
                                                    color: '#333',
                                                }}
                                            >
                                                To Be Announced
                                            </span>
                                        </div>
                                    }
                                    {/* {formData.winningNumbers.map((num, index) => (
                                        <div key={index} className={`row p-2 mb-3`}>

                                        </div>
                                    ))} */}
                                </Col>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewLottery;
