import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Badge, Modal, Form } from "react-bootstrap";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import globalSwal from "../../common/swalService";
import LotteryService from "../../service/api/lottery.service";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import PageBreadcrumb from "../../common/PageBreadcrum";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import './lotteryStyle.css'
import NumbersChart from "./winner-numbers-chart";
import FinanceMonitoring from "../../dashboard/FinanceMonitoring";
import { useSelector } from "react-redux";
import { LayoutDashboard } from "lucide-react";
import { BorderSpinner } from "../../common/Spinner";
import InfoComponent from "./Info";
const MakeWinner = () => {
    const lotteryService = LotteryService();
    const skin = useSelector((state) => state.ui.skin);
    const navigate = useNavigate();
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const [jackpotNumber, setJackpotNumber] = useState(null);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [canMake, setCanMake] = useState(false);
    const { id, lotteryDetails } = location.state;


    useEffect(() => {
        if (lotteryDetails) {

            const cutOffTime = new Date(lotteryDetails.cutOffTime).getTime();
            const currentTime = Date.now();


            const value = cutOffTime > currentTime;

            setCanMake(value);
        }
    }, [lotteryDetails])
    const toggleNumber = (number) => {
        if (selectedNumbers.includes(number)) {
            setSelectedNumbers(selectedNumbers.filter((num) => num !== number));
        } else if (selectedNumbers.length < 5) {
            setSelectedNumbers([...selectedNumbers, number]);
        } else {
            toast.error("You can select up to 5 numbers only.");
        }
    };

    const selectJackpot = (number) => {
        setJackpotNumber(number === jackpotNumber ? null : number);
    };

    const confirmSelection = () => {
        if (selectedNumbers.length !== 5 || jackpotNumber === null) {
            toast.error("Please select 5 main numbers and 1 jackpot number.");
            return;
        }

        globalSwal.confirm({
            title: "Are you sure?",
            text: "Do you really want to change the current status?",
            onConfirm: async () => {
                const res = await lotteryService.makeManualLotteryWinning(id, {
                    winningNumbers: selectedNumbers,
                    jackpotNumber: jackpotNumber,
                });
                if (res?.code === 200) {
                    globalSwal.success({
                        title: "Success!",
                        text: "Winning numbers updated successfully.",
                    });
                    navigate('/lottery-list')
                    // toast.success(res?.message || "Status updated successfully.");
                } else {
                    toast.error(res?.message || "Failed to update status.");
                }
            },
        });
    };

    const getNubmers = async () => {
        try {
            const res = await lotteryService.getLotteryWinningNumbers(id);
            console.log(res, "hello world");
            if (res.code === 200) {
                setData(res?.data);
            }
            else {
                throw new Error(res?.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getNubmers();
    }, []);

    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className="align-items-center mb-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between  text-end">
                                {/* <h4 className="card-title ">View Lottery Details</h4> */}
                                <PageBreadcrumb title="Manual Lottery Winner Selection" />

                                <Link to="/lottery-list" className="btn btn-primary ms-2">
                                    Back
                                </Link>
                            </div>
                        </Card.Header>

                        <div className="content">


                            {/* Information Section */}
                            <Card className="mb-4">
                                <Card.Header><h2>Frequently Chosen Numbers Overview</h2></Card.Header>
                                <Card.Body>
                                    {isLoading ? <>
                                        <div className="d-flex  my-5 justify-content-center align-content-center">

                                            <BorderSpinner />
                                        </div>
                                    </> : <>

                                        <NumbersChart data={data} isLoading={isLoading} skin={skin} />
                                    </>}

                                    {/* <FinanceMonitoring/> */}
                                </Card.Body>
                            </Card>

                            {/* Number Selection */}
                            <Card className="mb-4">
                                <Card.Header>Select Winning Numbers (5 main numbers and 1 jackpot)</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col md={8}>
                                            <h5>Main Numbers (1 to 36)</h5>
                                            <div className="number-grid">
                                                {[...Array(36)].map((_, i) => {
                                                    const number = i + 1;
                                                    return (
                                                        <motion.div
                                                            key={number}
                                                            className={`number-ball ${selectedNumbers.includes(number) ? "selected" : ""}`}
                                                            whileHover={{ scale: 1.2 }}
                                                            onClick={() => toggleNumber(number)}
                                                        >
                                                            {number}
                                                        </motion.div>
                                                    );
                                                })}
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <h5>Jackpot Numbers (1 to 10)</h5>
                                            <div className="jackpot-grid">
                                                {[...Array(10)].map((_, i) => {
                                                    const number = i + 1;
                                                    return (
                                                        <motion.div
                                                            style={{ border: jackpotNumber === number ? '2px solid green' : 'none' }}
                                                            key={number}
                                                            className={`number-ball ${jackpotNumber === number ? "selected" : ""}`}
                                                            whileHover={{ scale: 1.2 }}
                                                            onClick={() => selectJackpot(number)}
                                                        >
                                                            {number}
                                                        </motion.div>
                                                    );
                                                })}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Button title="Winning can be confirmed between cut off time and draw time" disabled={canMake} variant="success" onClick={confirmSelection}>
                                        Confirm Winning Numbers
                                    </Button>
                                    <span className="mb-4 position-relative " style={{ top: '-11px' }}><InfoComponent message={'Winning can be confirmed between cut off time and draw time'} /></span>
                                </Card.Footer>
                            </Card>


                        </div>
                    </Card>
                </div>
            </div>

        </React.Fragment>
    );
};

export default MakeWinner;
