const capitalizeFirstLetter = (string) => {

  if (!string) return '';
  if (typeof string !== 'string') {
    // console.log(string,'hey thereeeeeee')
    return string;
  }
  // return string
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export default capitalizeFirstLetter