import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import {
    dashboardMenu,
    applicationsMenu,
    pagesMenu,
    uiElementsMenu
} from "../data/Menu";
import Logo from "../common/logo";

const checkAccess = (moduleId, type) => {
    // console.log("Checking access",moduleId,type)
    var data = JSON.parse(localStorage.getItem("user")).role;
    var loginDetails = JSON.parse(localStorage.getItem("user"));
    if (data && loginDetails.type !== 'superadmin') {
        console.log("data access",data)
        for (let i = 0; i < data.resource.length; i++) {
            if (data.resource[i].moduleId == moduleId) {
                return data.resource[i].permissions.includes(type) ? true : false;
            }
        }
    } else {
        return true;
    }
}

export default class Sidebar extends Component {
    toggleFooterMenu = (e) => {
        e.preventDefault();
        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    }

    render() {
        return (
            <div className="sidebar" style={{ opacity: 1 }}>
                <div className="sidebar-header">
                    <Logo />
                </div>
                <PerfectScrollbar className="sidebar-body" ref={ref => this._scrollBarRef = ref}>
                    <SidebarMenu checkAccess={checkAccess} onUpdateSize={() => this._scrollBarRef.updateScroll()} />
                </PerfectScrollbar>
                {/* <div className="sidebar-footer">
                    <div className="sidebar-footer-top">
                        <div className="sidebar-footer-thumb">
                            <img src={userAvatar} alt="" />
                        </div>
                        <div className="sidebar-footer-body">
                            <h6><Link to="../pages/profile.html">Shaira Diaz</Link></h6>
                            <p>Premium Member</p>
                        </div>
                        <Link onClick={this.toggleFooterMenu} to="" className="dropdown-link"><i className="ri-arrow-down-s-line"></i></Link>
                    </div>
                    <div className="sidebar-footer-menu">
                        <nav className="nav">
                            <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link>
                            <Link to=""><i className="ri-profile-line"></i> View Profile</Link>
                        </nav>
                        <hr />
                        <nav className="nav">
                            <Link to=""><i className="ri-question-line"></i> Help Center</Link>
                            <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
                            <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link>
                            <Link to=""><i className="ri-logout-box-r-line"></i> Log Out</Link>
                        </nav>
                    </div>
                </div> */}
            </div>
        )
    }
}

class SidebarMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSubmenu: this.getStoredSubmenuState(), // Retrieve stored state
        };
    }

    getStoredSubmenuState = () => {
        const storedState = localStorage.getItem("openSubmenu");
        return storedState ? JSON.parse(storedState) : null;
    }

    storeSubmenuState = (key) => {
        localStorage.setItem("openSubmenu", JSON.stringify(key));
    }

    toggleSubMenu = (e, key) => {
        e.preventDefault();
        this.setState(prevState => {
            const newOpenSubmenu = prevState.openSubmenu === key ? null : key;
            this.storeSubmenuState(newOpenSubmenu);
            return { openSubmenu: newOpenSubmenu };
        }, this.props.onUpdateSize);
    }

    populateMenu = (item) => {
        const { checkAccess } = this.props;
        // console.log(item,"hidddennnnnnnnnnnnn")

        const menu = item.map((m, key) => {
            const hidden = checkAccess(m.moduleId, 'list');
            let sm;
            console.log(m,"hidddennnnnnnnnnnnn")
            if (m.submenu) {
                sm = m.submenu.map((sm, subKey) => {
                    return (
                        hidden && <NavLink to={sm.link} className="nav-sub-link" key={subKey}>{sm.label}</NavLink>
                    );
                });
            }

            const isSubmenuOpen = this.state.openSubmenu === key;

            return (
                <li key={key} className={`nav-item ${isSubmenuOpen ? 'show' : ''}`}>
                    {(!sm) ? (
                        hidden && <NavLink to={m.link} className="nav-link"><i className={m.icon}></i> <span>{m.label}</span></NavLink>
                    ) : (
                        hidden && <div onClick={(e) => this.toggleSubMenu(e, key)} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            );
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="nav-group show">
                    {this.populateMenu(dashboardMenu)}
                </div>
                {/* Uncomment the following blocks to include other menu sections */}
                {/* <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Applications</div>
                    {this.populateMenu(applicationsMenu)}
                </div>
                <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Pages</div>
                    {this.populateMenu(pagesMenu)}
                </div>
                <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>UI Elements</div>
                    {this.populateMenu(uiElementsMenu)}
                </div> */}
            </React.Fragment>
        );
    }
}

window.addEventListener("click", function (e) {
    // Close sidebar footer menu when clicked outside of it
    let tar = e.target;
    let sidebar = document.querySelector(".sidebar");
    if (!tar.closest(".sidebar-footer") && sidebar) {
        sidebar.classList.remove("footer-menu-show");
    }

    // Hide sidebar offset when clicked outside of sidebar
    if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
        document.querySelector("body").classList.remove("sidebar-show");
    }
});

window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("sidebar-skin");

    let HTMLTag = document.querySelector("html");

    if (skinMode) {
        HTMLTag.setAttribute("data-sidebar", skinMode);
    }
});













// import React, { Component } from "react";
// import { Link, NavLink } from "react-router-dom";
// import PerfectScrollbar from "react-perfect-scrollbar";
// import userAvatar from "../assets/img/img1.jpg";
// import {
//     dashboardMenu,
//     applicationsMenu,
//     pagesMenu,
//     uiElementsMenu
// } from "../data/Menu";
// import Logo from "../common/logo";


// const checkAccess = (moduleId, type) => {
//     var data = JSON.parse(localStorage.getItem("user")).role;
//     var loginDetails = JSON.parse(localStorage.getItem("user"));
//     if (data && loginDetails.type != 'superadmin') {
//         for (let i = 0; i < data.resource.length; i++) {
//             if (data.resource[i].moduleId == moduleId) {
//                 return data.resource[i].permissions.includes(type) ? true : false
//             }
//         }
//     } else {
//         return true;
//     }
// }

// export default class Sidebar extends Component {
//     toggleFooterMenu = (e) => {
//         e.preventDefault();

//         let parent = e.target.closest(".sidebar");
//         parent.classList.toggle("footer-menu-show");
//     }


//     render() {

//         return (
//             <div className="sidebar " style={{ opacity: 1 }}>

//                 <div className="sidebar-header">
//                     <div className="">
//                         <Logo />
//                     </div>



//                     {/* <Link to="/role-management" className="sidebar-logo">KripaBets</Link> */}
//                 </div>
//                 <PerfectScrollbar className="sidebar-body" ref={ref => this._scrollBarRef = ref}>
//                     <SidebarMenu checkAccess={checkAccess} onUpdateSize={() => this._scrollBarRef.updateScroll()} />
//                 </PerfectScrollbar>
//                 {/* <div className="sidebar-footer">
//                     <div className="sidebar-footer-top">
//                         <div className="sidebar-footer-thumb">
//                             <img src={userAvatar} alt="" />
//                         </div>
//                         <div className="sidebar-footer-body">
//                             <h6><Link to="../pages/profile.html">Shaira Diaz</Link></h6>
//                             <p>Premium Member</p>
//                         </div>
//                         <Link onClick={this.toggleFooterMenu} to="" className="dropdown-link"><i className="ri-arrow-down-s-line"></i></Link>
//                     </div>
//                     <div className="sidebar-footer-menu">
//                         <nav className="nav">
//                             <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link>
//                             <Link to=""><i className="ri-profile-line"></i> View Profile</Link>
//                         </nav>
//                         <hr />
//                         <nav className="nav">
//                             <Link to=""><i className="ri-question-line"></i> Help Center</Link>
//                             <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
//                             <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link>
//                             <Link to=""><i className="ri-logout-box-r-line"></i> Log Out</Link>
//                         </nav>
//                     </div>
//                 </div> */}
//             </div>
//         )
//     }
// }

// class SidebarMenu extends Component {

//     populateMenu = (m) => {
//         const { checkAccess } = this.props;
//         const menu = m.map((m, key) => {
//             const hidden = checkAccess(m.moduleId, 'list');
//             let sm;
//             if (m.submenu) {
//                 sm = m.submenu.map((sm, key) => {
//                     return (
//                         hidden && <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
//                     )
//                 })
//             }

//             return (
//                 <li key={key} className="nav-item">
//                     {(!sm) ? (
//                         hidden && <NavLink to={m.link} className="nav-link"><i className={m.icon}></i> <span>{m.label}</span></NavLink>
//                     ) : (
//                         hidden && <div onClick={this.toggleSubMenu} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
//                     )}
//                     {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
//                 </li>
//             )
//         });

//         return (
//             <ul className="nav nav-sidebar">
//                 {menu}
//             </ul>
//         );
//     }

//     // Toggle menu group
//     toggleMenu = (e) => {
//         e.preventDefault();

//         let parent = e.target.closest('.nav-group');
//         parent.classList.toggle('show');

//         this.props.onUpdateSize();
//     }

//     // Toggle submenu while closing siblings' submenu
//     toggleSubMenu = (e) => {
//         e.preventDefault();

//         let parent = e.target.closest('.nav-item');
//         let node = parent.parentNode.firstChild;

//         while (node) {
//             if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
//                 node.classList.remove('show');
//             node = node.nextElementSibling || node.nextSibling;
//         }

//         parent.classList.toggle('show');

//         this.props.onUpdateSize();
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="nav-group show">
//                     {/* <div className="nav-label" onClick={this.toggleMenu}>Dashboard</div> */}
//                     {this.populateMenu(dashboardMenu)}
//                 </div>
//                 {/* <div className="nav-group show">
//                     <div className="nav-label" onClick={this.toggleMenu}>Applications</div>
//                     {this.populateMenu(applicationsMenu)}
//                 </div>
//                 <div className="nav-group show">
//                     <div className="nav-label" onClick={this.toggleMenu}>Pages</div>
//                     {this.populateMenu(pagesMenu)}
//                 </div>
//                 <div className="nav-group show">
//                     <div className="nav-label" onClick={this.toggleMenu}>UI Elements</div>
//                     {this.populateMenu(uiElementsMenu)}
//                 </div> */}
//             </React.Fragment>
//         )
//     }
// }

// window.addEventListener("click", function (e) {
//     // Close sidebar footer menu when clicked outside of it
//     let tar = e.target;
//     let sidebar = document.querySelector(".sidebar");
//     if (!tar.closest(".sidebar-footer") && sidebar) {
//         sidebar.classList.remove("footer-menu-show");
//     }

//     // Hide sidebar offset when clicked outside of sidebar
//     if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
//         document.querySelector("body").classList.remove("sidebar-show");
//     }
// });

// window.addEventListener("load", function () {
//     let skinMode = localStorage.getItem("sidebar-skin");

//     let HTMLTag = document.querySelector("html");

//     if (skinMode) {
//         HTMLTag.setAttribute("data-sidebar", skinMode);
//     }
// });